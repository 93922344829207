table td {
    position: relative;
}
.autocomplete-dropdown{
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    color: black;
    border-radius: 5px;
    width: 95%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.dropdown-item{
    padding: 5px;
    cursor: pointer;
    border-bottom: #ccc 1px solid;
}
.dropdown-item:hover{
    background-color: #f9f9f9;
}

.alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -100%);
    background-color: #fff;
    width: 30%;
    height: 30%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    justify-content: center;
    z-index: 1000;
}

.alert h3 {
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
    padding-top: 100px;
}

.card_btns {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 0;
    list-style: none;
}

.card_btns button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.card_btns li:first-child button {
    background-color: red;
    color: white;
}


.card_btns li:last-child > button{
    background-color: green;
    color: white;
}

.card_btns button:hover {
    opacity: 0.8;
}
