.service {
    margin-left: 10px;
}
.service select {
    width: 220px;
    height: 26px;
}
.service textarea {
    width: 250px;
    height: 120px;
}
.service button {
    margin-top: 25px;
}
.service_request input:read-only, .service_request textarea:read-only {
    background: none;
    border: none;
    color: aliceblue;
}
.service_request select:disabled {
    background: none;
    border: none;
    color: aliceblue;
}
.kuro-service .alert {
    margin: 10px;
    background-color: black;
    width: fit-content;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, green 0%, green 50%, rgb(2, 52, 2) 50%, rgb(2, 52, 2) 100%);
    background-size: 200% 100%;
}
.kuro-service table {
    width: fit-content;
}
.kuro-service svg:hover {
    cursor: pointer;
    opacity: 80%;
}
.kuro-service button:disabled {
    cursor: not-allowed;
    opacity: 50%;
    position: relative;
}
.kuro-service button:disabled:hover::after {
    content: 'Enter all the required fields';
    position: absolute;
    width: 100%;
    background: black;
    padding: 10px;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
    color: aliceblue;
    margin-left: 10px;
}