.main,
.main * {
    box-sizing: border-box;
}
.main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 245px);
}
.pg_mh {
    padding-left: 10px;
    padding-right: 10px;
}
.page-title {
    margin: 0 0 20px;
}
.txt-center {
    text-align: center;
}
.txt-right {
    text-align: right;
}
.page-title.txt-center {
    text-align: center;
}
.page-title h1 {
    color: rgba(0, 188, 235, 0.8);
}
.cnt-wrap .text {
    margin: 0 0 30px;
}
svg.mail,
svg.phone,
svg.discuss {
    fill: rgba(0, 188, 235, 0.8);
    margin: 0 8px -2px 0;
}
svg.new-win {
    fill: #0175a2;
    margin: 0 0 -2px 8px;
}
.wa-chat {
    bottom: 50px;
    line-height: 0;
    position: fixed;
    right: 30px;
    z-index: 2;
}
.wa-chat a {
    background: #60d66a;
    border-radius: 50%;
    display: inline-block;
    line-height: 0;
}
.wa-chat a:hover {
    box-shadow: 0px 0px 4px 3px #666;
}
.wa-chat a svg {
    height: 50px;
    width: 50px;
}
.nav.arrow {
    height: 40px;
    padding: 8px 4px;
    width: 40px;
}
.nav.prev,
.nav.next {
    cursor: pointer;
}
.nav.arrow::before {
    border-bottom: 7px solid #4d4c4c;
    border-right: 7px solid #4d4c4c;
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    transform: rotate(-45deg);
}
.nav.arrow.prev::before {
    transform: rotate(135deg);
}
.home-bg {
    width: 34%;
    height: 67%;
    background: #282b34;
    transform: skewY(-197deg);
    position: absolute;
    top: 323px;
    right: 202px;
    border-radius: 5%;
    animation-name: kadulu;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    box-shadow: 3px 34px 1px 2px #222;
}
.msg.error,
.msg.warning {
    background-color: #000;
    border-radius: 5px;
    min-width: 350px;
    margin: 0 auto 10px;
    padding: 10px;
    display: inline-block;
}
.msg.error p,
.msg.warning p,
.msg.warning ul {
    color: #b0b0b0;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
}
.msg.error svg,
.msg.warning svg {
    margin: 0 20px 0 0;
    vertical-align: top;
    width: 24px;
}
.msg.warning ul li {
    display: inline-block;
}
.pre-loader {
    display: inline-block;
    margin-top: 30px;
    width: 80px;
    height: 80px;
}
.pre-loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #00bceb;
    border-color: #00bceb transparent #00bceb transparent;
    animation: preloader 1.2s linear infinite;
}
.search_container {
    width: fit-content;
    margin: 20px 0;
}
.col_2 {
    display: flex;
    gap: 30px;
    margin: 20px 0;
}
.dgrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.txt-center {
    text-align: center;
}
.box {
    border: 2px solid;
    width: fit-content;
    padding: 8px;
    margin-bottom: 20px;
}
.limit_handler {
    margin: 30px 0;
}
.stock-reg-title::first-letter {
    text-transform: capitalize;
}
select, input, textarea {
    width: 100%;
}
input[type="radio"] {
    width: 20px;
}
.scroll-up {
    position: fixed;
    right: 100px;
    bottom: 100px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search_wrap {
    margin: 20px 0;
}
.build-type {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.loading-wrapper {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%) translateY(-40%);
}
.loading-animation {
    width: 40px;
    height: 40px;
    border: 2px solid rgb(237, 34, 36);
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s ease-out 0s infinite;
    margin: 10px 0;
}
.notes {
    display: grid;
    grid-template-columns: 1fr;
}
.instructions {
    color:grey;
    display: inline-flex;
    gap: 5px;
    position: relative;
}
.instructions .instr {
    cursor: pointer;
    height: 22px;
}
.instructions .list {
    visibility: hidden;
    background-color: #000;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    left: 120px;
    padding: 10px;
    position: absolute;
    text-align: left;
    z-index: 3;
}
.instructions .list br {
    margin-bottom: 8px;
}
.instructions .list b {
    font-weight: 500;
}
.instructions .instr:hover .list {
    visibility: visible;
}
.note_img {
    border-radius: 30px;
    width: 20px;
    border: none;
    height: 22px;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 50vw;
    color:white;
}
.list {
    background-color:#c4bcee;
    border-radius: 5px;
    padding: 3px;
    font-size: 12px;
    font-weight: 600;
    margin: 2px;
}
.list-container {
    display: flex;
    flex-wrap: wrap;
    gap:2px;
}
.arrow {
    font-size: 10px;
    min-width:20px !important;
    text-align: center;
    padding: 2px;
    border-radius: 5px;
    margin: auto;
    position:absolute;
    top:2px;
    right:2px;
    margin-bottom: 10px;
}
.modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    height: auto;
    min-Height:300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position:relative;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
.text-center {
    text-align: center;
}
.display-center {
    display: flex;
    justify-content: space-around;
  align-items: center;
  gap:10px;
}
.display-grid {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:10px;
    color:#000
}
  
@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}
@keyframes preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 37.5em) {
    .pg_mh {
        padding-left: 15px;
        padding-right: 15px;
    }
    .notes {
        grid-template-columns: 3fr 2fr;
    }
}

@media only screen and (min-width: 64em) {
    .pg_mh {
        padding-left: 20px;
        padding-right: 20px;
    }
    .mx-width {
        padding: 0 20px;
    }
    select,
    input,
    textarea {
        width: 100%;
    }
}