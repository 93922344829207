@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300&display=swap');

html, body, header, footer, h1, h2, h3, h4, h5, h6, ul, ol, li, p, a, span {
    margin: 0;
    padding: 0;
}
body {
    background: #1d1e23;
    color: #4d4c4c;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
body * {
    font: inherit;
    letter-spacing: 1px;
}
body, body * {
    box-sizing: border-box;
}
.pri-0 {
    font-size: 28px;
    line-height: 1.214em;
    margin-bottom: 1.214em;
    font-weight: 400;
}
h1,
.pri-1,
h2,
.pri-2 {
    font-size: 22px;
    line-height: 1.167em;
    margin-bottom: 1.167em;
    font-weight: 300;
}
h3,
.pri-3 {
    font-size: 20px;
    line-height: 1.2em;
    margin-bottom: 1.2em;
    font-weight: 400;
}
h4,
h5,
.pri-4,
.pri-5,
h6,
.pri-6,
p,
li {
    font-size: 16px;
    line-height: 1.375em;
    margin-bottom: 0.75em;
    font-weight: 300;
}
li,
li a {
    line-height: 1.25em;
    margin-bottom: 1.25em;
}
li a {
    font-weight: 400;
}
p.util,
.util * {
    font-size: 14px;
    line-height: 1.286em;
    margin-bottom: 1.286em;
    font-weight: 300;
}
p strong,
p.util strong {
    font-weight: 400;
}
a {
    color: #00bceb;
    text-decoration: none;
    outline: none;
}
a:hover,
a:focus {
    color: #007493;
    text-decoration: none;
}
a:active {
    color: #005073;
}
.touch a:hover,
.touch a:active {
    text-decoration: none;
}
button {
    background: #6953d2;
    border: transparent;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 15px 10px;
    text-transform: uppercase;
    font-size: 12px;
    min-width: 200px;
    font-weight: 500;
}
.button:disabled {
    background: #4a4a4a;
    color: #000;
    cursor: not-allowed;
}
button:hover {
    background: #4d3c9f;
}
.txt_link,
button.txt_link {
    background: none;
    border: none;
    color: #00bceb;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.txt_link:hover,
.txt_link:focus,
button.txt_link:hover,
button.txt_link:focus {
    background: none;
    color: #007493;
    text-decoration: none;
}
.txt_link:active,
button.txt_link:active {
    color: #005073;
}
button.react-datepicker__navigation {
    min-width: auto;
}
ul,
ol {
    margin-bottom: 1.429em;
}
ul                 { list-style-type: disc; }
ul.no_bullets      { list-style-type: none; }
ol                 { list-style-type: decimal; }
ol.u-alpha {
    list-style-type: upper-alpha;
}
ol.l-alpha {
    list-style-type: lower-alpha;
}
ol.u-roman {
    list-style-type: upper-roman;
}
ol.l-roman {
    list-style-type: lower-roman;
}
ul li              { margin-left: 17px; }
ul.no_bullets li   { margin-left: 0; }
ol li              { margin-left: 25px; }
ul.bullets {
    list-style-type: none;
}
ul.bullets li {
    position: relative;
}
ul.bullets li::before {
    border-bottom: 4px solid transparent;
    border-left: 6px solid;
    border-top: 4px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: -15px;
    top: 4px;
    position: absolute;
    width: 0;
}
strong {
    font-weight: 400;
}
.txt-blue {
    color: #00bceb;
}
.txt-red {
    color: #db0b5b;
}
.txt-white {
    color: #ffffff;
}
.txt-light {
    color: #f6f1ed;
}
.txt-c-grey {
    color: #cccccc;
}
.txt-light-grey {
    color: #aaa;
}
.txt-grey {
    color: #737e92;
}
.txt-dark-grey {
    color: #565656;
}
.txt-dark-head {
    color: #564f6f;
}
.txt-dark {
    color: #000;
}
.cap {
    letter-spacing: 2px;
    text-transform: uppercase;
}
.ls-4 {
    letter-spacing: 4px;
}
.btn {
    border-radius: 100px;
    cursor: pointer;
    display: block;
    font-size: 13px;
    line-height: 1.25em;
    margin-right: 20px;
    min-width: 140px;
    padding: 10px;
    text-align: center;
    width: 100%;
}
.btn:hover {
    text-decoration: none;
}
.btn.pri0 {
    border: 2px solid #db0b5b;
    color: #f6f1ed;
}
.btn.pri0.solid {
    background: #db0b5b;
    color: #fff;
    position: relative;
    font-weight: 600;
}
.btn.pri0.solid :hover {
    background-color: #33c9ef;
    border: 2px solid #33c9ef;
    color: #f6f1ed;
}
.btn.pri0.solid :active {
    background-color: #66d6f2;
    border: 2px solid #66d6f2;
    color: #f6f1ed;
}
.btn.pri0.solid :focus {
    box-shadow: 0 0 8px 0 #00bceb;
}
.btn.icon svg {
    margin: 0 10px -6px 0;
    width: 15px;
}
.btn.pri0.icon svg {
    fill: #f6f1ed;
}
.btn.pri1 {
    border: 2px solid #00bceb;
    color: #00bceb;
}
.btn.pri1.solid {
    background-color: #00bceb;
    color: #000;
    font-weight: 600;
}
.btn.pri1.solid :hover {
    background-color: #0175a2;
    border: 2px solid #0175a2;
    color: #f6f1ed;
}
.btn.pri1.solid :active {
    background-color: #0175a2;
    border: 2px solid #0175a2;
    color: #f6f1ed;
}
.btn.pri1.solid :focus {
    box-shadow: 0 0 8px 0 #0175a2;
}
.btn.pri1.icon svg {
    fill: #00bceb;
}
.btn.pri1.solid.icon svg {
    fill: #000;
}
.btn.light {
    border: 2px solid #f6f1ed;
    color: #00bceb;
}
.btn.light:hover {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #00bceb;
}
.btn.light:active {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #00bceb;
}
.btn.light:focus {
    box-shadow: 0 0 8px 0 #f6f1ed;
}
.btn.light.icon svg {
    fill: #00bceb;
}
.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus {
    border: 2px solid transparent;
    background: #4a4a4a;
    color: #000;
}
.text-link {
    color: #00bceb;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
}
.btns {
    margin-bottom: 0;
}
.btns li {
    display: block;
    margin: 0 0 20px;
}
:last-child {
    margin-bottom: 0;
}
.compact {
    margin-bottom: 0;
}

/*  Small   = 600 to 767 pixels */
@media only screen and (min-width: 37.5em) {
    .pri-0 {
        font-size: 36px;
        line-height: 1.222em;
        margin-bottom: 1.222em;
    }
    h1,
    .pri-1,
    h2,
    .pri-2 {
        font-size: 28px;
        line-height: 1.214em;
        margin-bottom: 1.214em;
        font-weight: 200;
    }
    h3,
    h3 i,
    h3 em,
    .pri-3,
    .pri-3 i,
    .pri-3 em {
        font-size: 22px;
        line-height: 1.273em;
        margin-bottom: 1.273em;
    }
    h4,
    .pri-4 {
        font-size: 20px;
        line-height: 1.2em;
        margin-bottom: 1.2em;
    }
    h6,
    .pri-6 {
        line-height: 1.5em;
        font-weight: 400;
    }
    p {
        line-height: 1.5em;
    }
    .btn {
        display: inline-block;
        width: auto;
    }
    .btns li {
        display: inline-block;
        margin: 0 20px 20px 0;
    }
    .btns li:last-child {
        margin: 0 0 20px;
    }
}

/* Medium  = 768 to 959 pixels */
@media only screen and (min-width: 48em) {
    .pri-0 {
        font-size: 46px;
        line-height: 1.1875em;
        margin-bottom: 1.1875em;
    }
    h1,
    .pri-1 {
        font-size: 36px;
        line-height: 1.217em;
        margin-bottom: 1.217em;
    }
    h2,
    .pri-2 {
        font-size: 32px;
        line-height: 1.4375em;
        margin-bottom: 0.875em;
    }
    h3,
    h3 i,
    h3 em,
    .pri-3,
    .pri-3 i,
    .pri-3 em {
        font-size: 24px;
        line-height: 1.286em;
        margin-bottom: 1em;
    }
    h4,
    .pri-4 {
        font-size: 22px;
        line-height: 1.27em;
        margin-bottom: 0.5em;
    }
    h5,
    .pri-5 {
        font-size: 18px;
        line-height: 1.27em;
        margin-bottom: 0.56em;
        font-weight: 400;
    }
    p.util,
    .util * {
        font-size: 14px;
        line-height: 1.429em;
        margin-bottom: 1.429em;
    }
}
.scroll-up { 
    position: fixed;
    right: 100px;
    bottom: 100px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}